
// @ts-nocheck
(function (){
    'use strict';

    angular
        .module('elogbooks.user.tasks')
        .controller('UserTaskCreateStep2Controller', UserTaskCreateStep2Controller);

    function UserTaskCreateStep2Controller(
        $scope,
        $state,
        $translate,
        $uibModal,
        apiClient,
        messenger,
        confirmationModal,
        rootResourceResponse,
        hasEvents,
        siteResponse,
        taskResponse,
        serviceProviderCollectionResponse,
        locationCollectionResponse,
        statutoryTypeCollectionResponse,
        elbSettings,
        assetsCollectionResponse,
        jobGroupCollectionResponse,
        lodash,
        nonVersionedServiceRoutinesCollectionResponse,
        versionedServiceRoutinesCollectionResponse,
        assetSelected,
        taskService,
        calendarRuleService,
        modulesService,
        serviceRoutineService,
        $q
    ) {
        var vm = this;
        var forceServiceRoutine = elbSettings.getSetting('task_force_service_routine').value;
        vm.task = angular.copy(taskResponse);
        vm.hasEvents = hasEvents;
        vm.task.serviceRoutineLinesLoading = false;
        vm.site = siteResponse;
        vm.assets = assetsCollectionResponse;
        vm.jobGroups = jobGroupCollectionResponse;
        vm.nonVersionedServiceRoutinesCollectionResponse = nonVersionedServiceRoutinesCollectionResponse;
        vm.versionedServiceRoutinesCollectionResponse = versionedServiceRoutinesCollectionResponse;
        vm.hideForm = false;
        vm.hideAssetButton = assetSelected ? true : false;
        vm.noRules = false;
        vm.cancel = cancel;
        vm.assetSelect = {};
        vm.serviceRoutineSelect = {};
        vm.task.serviceRoutineType = 'none';
        var selectedAsset = null;
        vm.serviceRoutinesLoading = false;
        vm.statuses = {
            0: {
                title: $translate.instant('ACTIVE'),
                value: 1
            },
            1: {
                title: $translate.instant('INACTIVE'),
                value: 0
            }
        };

        vm.selectedServiceRoutineOptions = [
            {label: 'NONE_SELECTED', type: 'none' },
            {label: 'NON_SFG20', type: 'non_versioned'},
            {label: 'SFG20', type: 'versioned'}
        ];

        function cancel() {
            $state.go('^', {}, {reload: 'dashboard.user.tasks.manage-tasks.list.details'});
        }

        if (vm.task.recurrenceSet) {
            angular.forEach(vm.task.recurrenceSet.rules, function(rule) {
                if (!rule.serviceRoutineLine) {
                    rule.serviceRoutineLine = undefined;
                }
            });
        }

        if (serviceProviderCollectionResponse.partnerships && serviceProviderCollectionResponse.partnerships.length > 0) {
            vm.partnershipSelect = {
                response : serviceProviderCollectionResponse,
                link : serviceProviderCollectionResponse ? serviceProviderCollectionResponse._links.self.href :  null,
                required: true,
                onSelect: changeServiceProvider,
                onRemove: removeServiceProvider,
                responseKeyPath: 'partnerships',
                linkParameters: {active: 1},
                itemHrefPath: '_links.serviceprovider.href',
                itemValuePath: '_links.serviceprovider.title'
            };
        }

        vm.operativeSelect = {
            disabled: false,
            responseKeyPath: 'operatives',
            itemHrefPath: '_links.self.href',
            itemValuePath: '_links.user.name'
        };

        vm.selectedNonVersionedServiceRoutineModel = {
            response: vm.nonVersionedServiceRoutinesCollectionResponse.count > 0 ? vm.nonVersionedServiceRoutinesCollectionResponse : null,
            link: vm.nonVersionedServiceRoutinesCollectionResponse.getLink('self'),
            linkParams: {active: 1, isVersioned: 0},
            required: false,
            responseKeyPath: 'routines',
            searchKey: 'nameReference',
            formatValue: serviceRoutineService.formatServiceRoutineValue,
            show: vm.nonVersionedServiceRoutinesCollectionResponse.routines.length > 0,
            onSelect: getServiceRoutineLines,
            onRemove: taskService.clearServiceRoutineLines.bind(this, forceServiceRoutine, vm.task),
            forceServiceRoutine: false
        };

        vm.selectedVersionedServiceRoutineModel = {
            response: vm.versionedServiceRoutinesCollectionResponse.count > 0 ? vm.versionedServiceRoutinesCollectionResponse : null,
            link: vm.versionedServiceRoutinesCollectionResponse.getLink('self'),
            linkParams: {active: 1, isVersioned: 1},
            required: false,
            responseKeyPath: 'routines',
            searchKey: 'nameReference',
            formatValue: serviceRoutineService.formatServiceRoutineValue,
            show: vm.versionedServiceRoutinesCollectionResponse.routines.length > 0,
            onSelect: getServiceRoutineLines,
            onRemove: taskService.clearServiceRoutineLines.bind(this, forceServiceRoutine, vm.task),
            forceServiceRoutine: false
        };

        vm.locationSelect = {
            response: locationCollectionResponse,
            responseKeyPath: 'locations',
            selected: null,
            disabled: locationCollectionResponse ? false : true
        };

        vm.jobGroupSelect = {
            response: jobGroupCollectionResponse,
            responseKeyPath: 'jobgroups',
            selected: null,
            disabled: jobGroupCollectionResponse ? false : true,
            isHierarchical: true
        };

        if (vm.assets && vm.assets.assets && vm.assets.assets.length > 0) {

            if (assetSelected !== null) {
                selectedAsset = lodash.find(vm.assets.assets, ['id', assetSelected.id]);
            }

            vm.assetSelect = {
                response: vm.assets,
                responseKeyPath: 'assets',
                required: taskResponse.type,
                linkParameters: {active: 1},
                link : lodash.has(assetsCollectionResponse, '_links.self.href') ? assetsCollectionResponse._links.self.href : null,
                selected: selectedAsset ? {
                    title: selectedAsset.name,
                    href: selectedAsset.getLink('self'),
                    object: selectedAsset
                } : null,
                onSelect: changeAsset,
                onRemove: removeAsset,
                disabled: selectedAsset ? true : false,
                populateDisabled: true
            };
        }

        vm.statutoryTypes = statutoryTypeCollectionResponse;
        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;
        vm.createAsset = createAssetModal;

        function getServiceRoutineLines(model) {
            taskService.getServiceRoutineLines(model, vm.forceServiceRoutine, vm.task)
        }

        // Fill form data on edit action
        if (taskResponse._links && typeof taskResponse._links.self !== 'undefined') {
            if (typeof taskResponse._links.serviceroutine !== 'undefined') {
                vm.serviceRoutineSelect.selected = {
                    href: vm.task.getLink('serviceroutine'),
                    object: vm.task._links.serviceroutine
                };
            }


            if (typeof taskResponse._links.serviceprovider !== 'undefined' && typeof vm.partnershipSelect !== 'undefined') {
                vm.partnershipSelect.selected = taskResponse._links.serviceprovider;
                vm.partnershipSelect.disabled = false;
                changeServiceProvider(true);
            }

            if (typeof taskResponse._links.operative !== 'undefined') {
                vm.operativeSelect.selected = taskResponse._links.operative;
                vm.operativeSelect.disabled = false;
            }

            if (vm.jobGroupSelect && typeof taskResponse._links.jobgroup !== 'undefined') {
                vm.jobGroupSelect.selected = taskResponse._links.jobgroup;
            }

            if (vm.assetSelect && typeof taskResponse._links.asset !== 'undefined') {
                vm.assetSelect.selected = taskResponse._links.asset;
                changeAsset();
                vm.assetSelect.disabled = false;
                if (taskResponse.getLink('serviceroutine') && forceServiceRoutine) {
                    vm.forceServiceRoutine = true;
                }
            }

            if (taskResponse._links.serviceroutine) {
                if (taskResponse._links.serviceroutine.isVersioned) {
                    vm.task.serviceRoutineType = 'versioned';
                } else {
                    vm.task.serviceRoutineType = 'non_versioned';
                }

                vm.serviceRoutineSelect = getServiceRoutineSelectModel();

                vm.serviceRoutineSelect.selected = {
                    href: taskResponse.getLink('serviceroutine'),
                    object: taskResponse._links.serviceroutine
                };
            }

            if (typeof taskResponse._links.location !== 'undefined') {
                vm.task.location = taskResponse._links.location;
                vm.locationSelect.selected = taskResponse._links.location.href;
                vm.locationSelect.disabled = false;
            }

            if (vm.task.getLink('location')) {
                apiClient.get(vm.task.getLink('location')).then(function (locationResponse) {
                    if (locationResponse) {
                        vm.location = locationResponse
                    }
                })
            }

            if (typeof taskResponse.recurrenceSet !== 'undefined') {
                vm.task.rules = taskResponse.recurrenceSet.rules;
            }

            taskResponse.status = taskResponse.status ? 1 : 0;
        }

        $scope.$watch('vm.task.serviceRoutineType', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                taskService.clearServiceRoutineLines(vm.forceServiceRoutine, vm.task);
                vm.selectedNonVersionedServiceRoutineModel.selected = null;
                vm.selectedVersionedServiceRoutineModel.selected = null;
            }
        });

        function removeAsset() {
            vm.assetSelect.selected = null;
            vm.forceServiceRoutine = false;

            taskService.loadServiceRoutines(null, vm.selectedVersionedServiceRoutineModel, vm.jobGroupSelect, vm.versionedServiceRoutinesCollectionResponse);
            taskService.loadServiceRoutines(null, vm.selectedNonVersionedServiceRoutineModel, vm.jobGroupSelect, vm.nonVersionedServiceRoutinesCollectionResponse);
        }

        function updateServiceRoutineSelect() {
            if (taskResponse && taskResponse._links && taskResponse._links.serviceroutine) {
                if (taskResponse._links.serviceroutine.isVersioned) {
                    vm.task.serviceRoutineType = 'versioned'
                } else {
                    vm.task.serviceRoutineType = 'non_versioned';
                }

                vm.serviceRoutineSelect = getServiceRoutineSelectModel();
                vm.serviceRoutineSelect.selected = {
                    href: vm.task.getLink('serviceroutine'),
                    object: vm.task._links.serviceroutine
                };
            }
        }

        /**
         * Change asset callback for assetSelect
         */
        function changeAsset() {
            vm.serviceRoutinesLoading = true;

            $q.all([
                taskService.loadServiceRoutines(vm.assetSelect, vm.selectedVersionedServiceRoutineModel, vm.jobGroupSelect, vm.versionedServiceRoutinesCollectionResponse),
                taskService.loadServiceRoutines(vm.assetSelect, vm.selectedNonVersionedServiceRoutineModel, vm.jobGroupSelect, vm.nonVersionedServiceRoutinesCollectionResponse)
            ]).then(function () {
                vm.serviceRoutinesLoading = false;
                updateServiceRoutineSelect()
            });
        }

        /**
         * Load service provider and setup operative form element
         */
        function changeServiceProvider(leaveOperative) {
            if (typeof vm.partnershipSelect.selected.href !== 'undefined') {
                if (typeof leaveOperative === 'object' || !leaveOperative) {
                    vm.operativeSelect.selected = null;
                }

                apiClient.get(vm.partnershipSelect.selected.href).then(function (response) {
                    //List operatives
                    if (response && typeof response._links.taskoperatives !== 'undefined') {
                        vm.operativeSelect.link = response._links.taskoperatives.href;
                        vm.operativeSelect.disabled = false;
                    }
                });
            }
        }

        /**
         * Disable and clear operatives
         */
        function removeServiceProvider() {
            vm.operativeSelect.disabled = true;
            vm.operativeSelect.clear();
            taskService.clearServiceRoutineLines(forceServiceRoutine, vm.task);
        }

        /**
         * Create task
         */
        function createAction() {
            var taskRequest = angular.copy(vm.task);
            taskRequest._links = {};

            //check if day rule is set on the frequency
            if (vm.forceServiceRoutine) {
                var ruleSet = true;
                taskRequest.rules.every(function(rule) {
                    if (!(rule.byDay || rule.byMonthDay)) {
                        messenger.error('ONE_OR_MORE_RULES_MISSING');
                        ruleSet = false;
                        return false;
                    } else {
                        return true;
                    }
                });

                if (!ruleSet) {
                    return false;
                }
            }

            // tasks require at least one reccurrance rule
            if (taskRequest.rules.length === 0) {
                vm.noRules = true;
                messenger.error('ONE_OR_MORE_RULES_MISSING');
                return false;
            }

            if (typeof vm.partnershipSelect.selected !== 'undefined') {
                taskRequest._links.serviceprovider = vm.partnershipSelect.selected;
            }

            if (vm.assetSelect && typeof vm.assetSelect.selected !== 'undefined') {
                taskRequest._links.asset = vm.assetSelect.selected;
            }

            if (vm.jobGroupSelect && typeof vm.jobGroupSelect.selected !== 'undefined') {
                taskRequest._links.jobgroup = vm.jobGroupSelect.selected;
            }

            if (typeof vm.locationSelect.selected !== 'undefined' && vm.locationSelect.selected) {
                if (!vm.locationSelect.selected.href) {
                    taskRequest._links.location = { href: vm.locationSelect.selected };
                } else {
                    taskRequest._links.location = vm.locationSelect.selected;
                }
            }

            if (typeof vm.operativeSelect.selected !== 'undefined') {
                taskRequest._links.operative = vm.operativeSelect.selected;
            }

            vm.serviceRoutineSelect = getServiceRoutineSelectModel();

            if (vm.serviceRoutineSelect && typeof !vm.serviceRoutineSelect.selected) {
                taskRequest._links.serviceroutine = {
                    value: vm.serviceRoutineSelect.selected.title,
                    title: vm.serviceRoutineSelect.selected.value,
                    href: vm.serviceRoutineSelect.selected.href
                }
            }

            if (typeof $state.params.assetResource !== 'undefined' ) {
                taskRequest._links.asset = vm.assetSelect.selected;
            }

            calendarRuleService.ruleTimeInMinutes(taskRequest.rules);

            apiClient.create(vm.site.getLink('tasks'), taskRequest).then(function (response) {
                if (response) {
                    if ($state.is("dashboard.user.tasks.manage-tasks.list.add.soft")
                        || $state.is("dashboard.user.tasks.manage-tasks.list.add.hard")
                    ) {
                        $state.go('^.^', {}, {reload: true}).then(function () {
                            messenger.success('ADD_SUCCESS');
                        })
                    } else {
                        $state.go('^', {}, {reload: true}).then(function () {
                            messenger.success('ADD_SUCCESS');
                        })
                    }
                }
            });
        }

        /**
         * Update task
         */
        function updateAction() {
            // tasks require at least one reccurrance rule
            if (vm.task.rules.length === 0) {
                vm.noRules = true;
                messenger.error('ONE_OR_MORE_RULES_MISSING');
                return false;
            }

            vm.task.recurrenceSet.rules = vm.task.rules;

            //Clear links
            var taskRequest = angular.copy(vm.task);

            let partnerShipSelected = null, assetSelected = null, serviceRoutineSelected = null;

            taskRequest._links = {};

            if (vm.partnershipSelect && vm.partnershipSelect.selected) {
                taskRequest._links.serviceprovider = vm.partnershipSelect.selected;
                partnerShipSelected = vm.partnershipSelect.selected ? vm.partnershipSelect.selected.href : null;
            }

            if (vm.assetSelect && vm.assetSelect.selected) {
                taskRequest._links.asset = vm.assetSelect.selected;
                assetSelected = vm.assetSelect.selected ? vm.assetSelect.selected.href : null;
                taskRequest.type = 1;
            }

            if (vm.jobGroupSelect && vm.jobGroupSelect.selected) {
                taskRequest._links.jobgroup = vm.jobGroupSelect.selected;
            }

            if (vm.locationSelect.selected && vm.locationSelect.selected) {
                if (!vm.locationSelect.selected.href) {
                    taskRequest._links.location = { href: vm.locationSelect.selected };
                } else {
                    taskRequest._links.location = vm.locationSelect.selected;
                }
            }

            if (vm.operativeSelect && vm.operativeSelect.selected) {
                taskRequest._links.operative = vm.operativeSelect.selected;
            }

            vm.serviceRoutineSelect = getServiceRoutineSelectModel();

            if (vm.serviceRoutineSelect && vm.serviceRoutineSelect.selected) {
                taskRequest._links.serviceroutine =  vm.serviceRoutineSelect.selected;
                serviceRoutineSelected = vm.serviceRoutineSelect.selected.href;
            }

            calendarRuleService.ruleTimeInMinutes(taskRequest.rules);

            // Checks if Generation Frequency Dates Range popup should appear
            let [valueRequiresApproval, rulesToRegenerate] = (() => {
                let isTaskDetailsChanged = vm.task.name !== taskResponse.name ||
                        vm.task.getLink('serviceprovider') !== partnerShipSelected ||
                        vm.task.getLink('serviceroutine') !== serviceRoutineSelected ||
                        vm.task.getLink('asset') !== assetSelected,
                    forceRegenerateAllRules = isTaskDetailsChanged,
                    valueRequiresApproval = false;
                let isFrequencyRuleChanged = rule => !rule.id;

                if (taskResponse.recurrenceSet.rules.length !== vm.task.rules.length) {
                    valueRequiresApproval = true;
                }

                // The angular.copy removes bits that angular added which broke comparison checks.
                let rulesCopy = angular.copy(vm.task.rules);

                angular.forEach(rulesCopy, (taskCopy) => {
                    if (forceRegenerateAllRules || isFrequencyRuleChanged(taskCopy)) {
                        valueRequiresApproval = true;
                        taskCopy.reGenerate = true;
                    }
                });

                if (!valueRequiresApproval) {
                    rulesCopy = [];
                }

                return [valueRequiresApproval, rulesCopy];
            })();

            if (vm.hasEvents && valueRequiresApproval && modulesService.isEnabled('advanced_ppm_planner') && taskRequest.status === 1) {
                $uibModal.open({
                    templateUrl: '/modules/user/tasks/generate/modal/advanced-task-generate.html',
                    controller: 'UserAdvancedTaskGenerateModalController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        config: function () {
                            return {
                                titleMessage: 'ARE_YOU_SURE_HEADER',
                                bodyMessage: 'ARE_YOU_SURE',
                                primaryText: 'YES',
                                primaryClass: 'btn-danger',
                                secondaryText: 'NO',
                                secondaryClass: 'btn-default'
                            }
                        },
                        rules: () => rulesToRegenerate
                    }
                }).result.then(
                    function (data) {
                        updateApiCall(angular.copy(data));
                    }
                );
            } else {
                updateApiCall();
            }

            function updateApiCall(generateData) {
                if (generateData) {
                    for (var i=0; i < generateData.rules.length; i++) {
                        generateData.rules[i].start = moment.utc(moment(generateData.rules[i].start).format('YYYY-MM-DD HH:mm:ss'));
                        if (typeof generateData.rules[i].end !== 'undefined') {
                            generateData.rules[i].end = moment.utc(moment(generateData.rules[i].end).format('YYYY-MM-DD HH:mm:ss'));
                        }
                    }
                    taskRequest.rules = generateData.rules;
                    taskRequest.note = generateData.note;
                }

                apiClient.update(vm.task.getLink('self'), taskRequest).then(function (response) {
                    if (response) {
                        $state.go('^', {}, {reload: 'dashboard.user.tasks.manage-tasks.list'}).then(function () {
                            messenger.success('UPDATE_SUCCESS');
                        });
                        $uibModalInstance.close({action:'update', task:vm.task});
                    }
                });
            }
        }

        /**
         * Delete task
         */
        function deleteAction() {
            return confirmationModal.open().result.then(
                function () {
                    return apiClient.delete(vm.task.getLink('delete')).then(function (response) {
                        if (response) {
                            $state.go('^.^.^', {}, {reload: true}).then(function () {
                                messenger.success('DELETE_SUCCESS');
                            })
                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                }
            );
        }

        function createAssetModal($event){
            $event.preventDefault();
            $event.stopPropagation();

            var eventModal = $uibModal.open({
                templateUrl: '/modules/user/assets/manage/add-edit/modal/asset-add-modal-form.html',
                controller: 'UserAssetsModalAddController',
                controllerAs: 'vm',
                resolve: {
                    siteResponse: siteResponse,
                    jobGroupsCollectionResponse: apiClient.get(rootResourceResponse.getLink('jobgroups')),
                    assetTypesCollectionResponse: apiClient.get(rootResourceResponse.getLink('assettypes')),
                    nonVersionedServiceRoutinesCollectionResponse: apiClient.get(rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 0 }),
                    versionedServiceRoutinesCollectionResponse: apiClient.get(rootResourceResponse.getLink('serviceroutines'), { active: 1, isVersioned: 1 }),
                    statutoryTypesCollectionResponse: statutoryTypeCollectionResponse,
                    serviceProviderResponse: apiClient.get(vm.partnershipSelect.selected.href),
                    locationCollectionResponse: apiClient.get(siteResponse.getLink('locations')),
                    assetScoreTypesResponse: apiClient.get(rootResourceResponse.getLink('assetscoretypes')),
                    canEditScoreTypes: true,
                }
            });

            eventModal.result.then(function (response) {
                if (response && typeof response.getLink === 'function') {
                    vm.assetSelect.selected = {
                        href : response.getLink('self'),
                        title : response.name
                    };
                    changeAsset();
                    if (forceServiceRoutine) {
                        vm.serviceRoutineSelect = getServiceRoutineSelectModel();
                        taskService.getServiceRoutineLines.bind(this, vm.serviceRoutineSelect, forceServiceRoutine, vm.task);
                    }
                }

                if (response && typeof response.recurrenceSet !== 'undefined') {
                    vm.task.rules = response.recurrenceSet.rules;
                }
            });
        }

        function getServiceRoutineSelectModel() {
            switch (vm.task.serviceRoutineType) {
                case 'versioned':
                    return vm.selectedVersionedServiceRoutineModel;
                case 'non_versioned':
                    return vm.selectedNonVersionedServiceRoutineModel;
                default:
                    break;
            }
        }
    }
})();
